import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  from,
  DefaultOptions,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'isomorphic-unfetch'

import { GRAPHQL_ENDPOINT, IS_SERVER_SIDE } from 'Constants'
import possibleTypesCms from './possibleTypesCms.json'

const createClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  const linkCms = new HttpLink({
    uri: `${GRAPHQL_ENDPOINT}${token ? `?token=${token}` : ``}`,
    credentials: 'same-origin',
    // Use fetch() polyfill on the server
    fetch: (IS_SERVER_SIDE && fetch) || undefined,
  })

  const cacheCms = IS_SERVER_SIDE
    ? new InMemoryCache({
        possibleTypes: possibleTypesCms,
      })
    : new InMemoryCache({
        possibleTypes: possibleTypesCms,
      }).restore((window as any).__APOLLO_STATE__)

  const ignoreCache: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const clientCms = new ApolloClient({
    ssrMode: IS_SERVER_SIDE,
    link: from([new RetryLink(), linkCms]),
    cache: cacheCms,
    // @NOTE ignore apollo cache completely if it is a CMS preview request
    defaultOptions: token ? ignoreCache : undefined,
  })

  return clientCms
}

export default createClient
