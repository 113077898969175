import { combineReducers } from 'redux'

import homepage from './homepage/reducers'
import meta from './meta/reducers'
import pages from './pages/reducers'
import projects from './projects/reducers'
import ui from './ui/reducers'

export const rootReducer = combineReducers({
  homepage,
  meta,
  pages,
  projects,
  ui,
})

export type RootState = ReturnType<typeof rootReducer>
