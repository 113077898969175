import React, { ReactElement, useEffect, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { animateScroll } from 'react-scroll'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { addBodyClass, removeBodyClass } from 'utils/bodyClass'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Header from 'view/components/header/Header'
import HeaderSpacer from 'view/components/header/HeaderSpacer'
import Helmet from 'view/components/helmet/DefaultHelmet'
import HomeSectionVideo from './HomeSectionVideo'
import HomeSectionImage from './HomeSectionImage'

import './Home.scss'

const mapStateToProps = (state: RootState) => ({
  homepage: state.homepage,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface HomeProps extends PropsFromRedux {
  active: boolean
}

const Home = ({ homepage, active }: HomeProps): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const handleScroll = () => {
        const node = wrapperRef.current
        const sections = node.querySelectorAll('.Home__section')
        let hasSticky = false

        sections.forEach((section) => {
          const title = section.querySelector('.Home__section__title')
          const sticky = section.querySelector('.Home__section__sticky')
          const y = title.getBoundingClientRect().y
          const headerHeight = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              '--header-height'
            )
          )

          if (y <= headerHeight) {
            sticky.classList.add('is-sticky')
            hasSticky = true
          } else {
            sticky.classList.remove('is-sticky')
          }

          if (hasSticky) {
            addBodyClass('home-has-sticky')
          } else {
            removeBodyClass('home-has-sticky')
          }
        })
      }

      wrapperRef.current.addEventListener('scroll', handleScroll, false)

      return () => {
        if (wrapperRef && wrapperRef.current) {
          wrapperRef.current.removeEventListener('scroll', handleScroll, false)
        }
      }
    }
  }, [wrapperRef])

  let index = -1
  const sections = homepage.items.map((item) => {
    const { id, hidden, color, asset, poster, related } = item
    const assetFirst = asset && asset.length > 0 && asset[0]
    const posterFirst = poster && poster.length > 0 && poster[0]
    const relatedFirst = related && related.length > 0 && related[0]

    if (hidden || !assetFirst) return null

    if (assetFirst.kind === 'video') {
      index++

      return (
        <HomeSectionVideo
          key={id}
          asset={assetFirst}
          poster={posterFirst}
          color={color}
          related={relatedFirst}
          index={index}
          wrapperRef={wrapperRef}
        />
      )
    } else if (assetFirst.kind === 'image') {
      index++

      return (
        <HomeSectionImage
          key={id}
          asset={assetFirst}
          color={color}
          related={relatedFirst}
          index={index}
          wrapperRef={wrapperRef}
        />
      )
    }

    return null
  })

  const helmetParams = {
    url: `${BASE_URL}`,
  }

  return (
    <>
      {active && <Helmet {...helmetParams} />}

      <Header type="home" wrapperRef={wrapperRef} active={active} />

      <div id="Home" className="Home" ref={wrapperRef}>
        <HeaderSpacer type="home" />

        <div className="Home__inner">{sections}</div>
      </div>

      <CookieBanner />
    </>
  )
}

export default connector(Home)
