import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Header from 'view/components/header/Header'
import HeaderSpacer from 'view/components/header/HeaderSpacer'
import Helmet from 'view/components/helmet/DefaultHelmet'

import './NotFound.scss'

type NotFoundProps = {
  active: boolean
}

const NotFound = ({ active }: NotFoundProps): ReactElement => {
  const helmetParams = {
    title: '404',
  }

  return (
    <>
      {active && <Helmet {...helmetParams} />}

      <Header type="notfound" active={active} />

      <div className="NotFound">
        <HeaderSpacer type="notfound" />

        <div className="NotFound__inner">
          <div className="NotFound__text">
            <strong>The page could not be found!</strong>
            <br />
            <Link to="/">Back home</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
