import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import ResizeObserver from 'resize-observer-polyfill'
import striptags from 'striptags'

import { RootState } from 'data/redux/rootReducer'
import {
  motionHeaderClaimVariants,
  motionHeaderNavVariants,
} from 'utils/motionVariants'

import './Header.scss'

const mapStateToProps = (state: RootState) => ({
  claim: state.meta.header,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface HeaderProps extends PropsFromRedux {
  active: boolean
  type: string
  wrapperRef?: any
}

const Header = ({
  claim,
  active,
  type,
  wrapperRef,
}: HeaderProps): ReactElement => {
  const headerRef = useRef<HTMLDivElement>(null)
  const [lastY, setLastY] = useState(0)
  const [showHeader, setShowHeader] = useState(true)
  const [showClaim, setShowClaim] = useState(true)

  const classes = classnames('Header Header--claim', `Header--${type}`)
  const claimClean = striptags(claim, ['br', 'strong', 'a'])

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const handleScroll = () => {
        const yPos = wrapperRef.current.scrollTop
        const showClaim = yPos < 250

        setShowClaim(showClaim)

        if (type === 'about') {
          setShowHeader(showClaim)
        }

        setLastY(yPos)
      }

      wrapperRef.current.addEventListener('scroll', handleScroll, false)

      return () => {
        if (wrapperRef && wrapperRef.current) {
          wrapperRef.current.removeEventListener('scroll', handleScroll, false)
        }
      }
    }
  }, [type, lastY])

  useEffect(() => {
    if (type === 'home' && active) {
      if (headerRef && headerRef.current) {
        const observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const { height } = entry.contentRect
            document.documentElement.style.setProperty(
              '--header-height',
              `${height}px`
            )
          }
        })

        observer.observe(headerRef.current)

        return () => {
          observer.disconnect()
        }
      }
    }
  }, [type, active])

  return (
    <header className={classes} ref={headerRef}>
      <motion.div
        className="Header__claim"
        variants={motionHeaderClaimVariants}
        initial="visible"
        animate={showClaim ? 'visible' : 'hidden'}
      >
        <div className="Header__claim__inner">
          <Link to="/" className="Header__claim__home">
            MORK-ULNES
          </Link>{' '}
          <span
            className="Header__claim__text"
            dangerouslySetInnerHTML={{ __html: claimClean }}
          />
        </div>
      </motion.div>

      {type !== 'notfound' && (
        <motion.nav
          className="Header__nav"
          variants={motionHeaderNavVariants}
          initial="visible"
          animate={showHeader ? 'visible' : 'hidden'}
        >
          <div className="Header__nav__inner">
            <ul className="Header__nav__list">
              <li className="Header__nav__item">
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    `Header__nav__link${
                      isActive ? ' Header__nav__link--active' : ''
                    }`
                  }
                >
                  About
                </NavLink>
              </li>
              <li className="Header__nav__item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `Header__nav__link${
                      isActive ? ' Header__nav__link--active' : ''
                    }`
                  }
                >
                  Selected Projects,
                </NavLink>{' '}
                <NavLink
                  to="/index"
                  className={({ isActive }) =>
                    `Header__nav__link${
                      isActive ? ' Header__nav__link--active' : ''
                    }`
                  }
                >
                  Projects
                </NavLink>
              </li>
              <li className="Header__nav__item">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    `Header__nav__link${
                      isActive ? ' Header__nav__link--active' : ''
                    }`
                  }
                >
                  Contact
                </NavLink>
              </li>
            </ul>

            <div className="Header__nav__small">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `Header__nav__link${
                    isActive ? ' Header__nav__link--active' : ''
                  }`
                }
              >
                About,
              </NavLink>{' '}
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `Header__nav__link${
                    isActive ? ' Header__nav__link--active' : ''
                  }`
                }
              >
                Contact
              </NavLink>
              <br />
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `Header__nav__link${
                    isActive ? ' Header__nav__link--active' : ''
                  }`
                }
              >
                Selected Projects,
              </NavLink>{' '}
              <NavLink
                to="/index"
                className={({ isActive }) =>
                  `Header__nav__link${
                    isActive ? ' Header__nav__link--active' : ''
                  }`
                }
              >
                Projects
              </NavLink>
            </div>
          </div>
        </motion.nav>
      )}
    </header>
  )
}

export default connector(Header)
