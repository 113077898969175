import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import moment from 'moment-timezone'

import './Clock.scss'

type ClockProps = {
  timezone: string
}

const fiveMinuteMarkers = Array.from(new Array(60), (x, i) => i).filter(
  (m) => m % 5 === 0
)
const markerIndexToRadians = (markerIndex: number) =>
  (Math.PI * markerIndex) / 30

const draw5MinMarker = (markerIndex: number): ReactElement => (
  <g style={{ stroke: 'black' }}>
    <line
      strokeWidth="1"
      x1={102 * Math.cos(markerIndexToRadians(markerIndex))}
      y1={102 * Math.sin(markerIndexToRadians(markerIndex))}
      x2={108 * Math.cos(markerIndexToRadians(markerIndex))}
      y2={108 * Math.sin(markerIndexToRadians(markerIndex))}
    />
  </g>
)

const getTime = (timezone: string) => {
  const now = moment.tz(timezone.replace('__', '/'))

  return {
    hours: now.hours(),
    minutes: now.minutes(),
    seconds: now.seconds(),
  }
}

const Clock = ({ timezone }: ClockProps): ReactElement => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 })

  useEffect(() => {
    setTime(getTime(timezone))

    const interval = window.setInterval(() => {
      setTime(getTime(timezone))
    }, 1000)

    return () => window.clearInterval(interval)
  }, [])

  const { hours, minutes, seconds } = time

  return (
    <svg className="Clock" viewBox="0 0 230 230">
      <g transform="translate(115,115)">
        <circle className="Clock__circle" r="110" />

        {fiveMinuteMarkers.map((m, i) => (
          <Fragment key={i}>{draw5MinMarker(m)}</Fragment>
        ))}

        <g
          className="Clock__hand__seconds"
          transform={`rotate(${seconds * 6},0,0)`}
        >
          <line x1="0" y1="0" x2="0" y2="-100" />
        </g>

        <g
          className="Clock__hand__hours"
          transform={`rotate(${hours * 30 + (minutes / 60) * 30},0,0)`}
        >
          <line strokeWidth="1" x1="0" y1="0" x2="0" y2="-70" />
        </g>

        <g
          className="Clock__hand__minutes"
          transform={`rotate(${minutes * 6},0,0)`}
        >
          <line strokeWidth="1" x1="0" y1="0" x2="0" y2="-100" />
        </g>
      </g>
    </svg>
  )
}

export default Clock
