import React, { ReactElement, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Header from 'view/components/header/Header'
import HeaderSpacer from 'view/components/header/HeaderSpacer'
import Helmet from 'view/components/helmet/DefaultHelmet'
import IndexItem from './IndexItem'

import './Index.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.items,
  index: state.pages.index,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface IndexProps extends PropsFromRedux {
  active: boolean
}

const Index = ({ index, projects, active }: IndexProps): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const items = projects.map((item) => <IndexItem key={item.id} item={item} />)

  const helmetParams = {
    title: (index && index.metaTitle) || null,
    description: (index && index.metaDescription) || null,
    url: `${BASE_URL}/index`,
  }

  return (
    <>
      {active && <Helmet {...helmetParams} />}

      <Header type="index" wrapperRef={wrapperRef} active={active} />

      <div className="Index" ref={wrapperRef}>
        <HeaderSpacer type="index" />
        <div className="Index__inner">
          <div className="Index__items">{items}</div>
        </div>
      </div>

      <CookieBanner />
    </>
  )
}

export default connector(Index)
