import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'

import Analytics from 'view/components/analytics/Analytics'
import Home from 'view/content/home/Home'
import Index from 'view/content/index/Index'
import NotFound from 'view/content/not-found/NotFound'
import Page from 'view/content/page/Page'
import Project from 'view/content/project/ProjectProvider'

import 'style/globals/index.scss'
import './App.scss'

const App = (): ReactElement => {
  const [x, setX] = useState('0')
  const [y, setY] = useState('0')
  const [projectLeft, setProjectLeft] = useState('0')
  const [activeComponent, setActiveComponent] = useState('home')
  const [loaded, setLoaded] = useState(false)
  const [countRequests, setCountRequests] = useState(0)
  const location = useLocation()

  useEffect(() => {
    let { pathname } = location
    pathname = pathname.substring(1)

    if (pathname === '') {
      setX('0')
      setY('0')
      setProjectLeft('0')
      setActiveComponent('home')
    } else if (pathname === 'index') {
      setX('-100%')
      setY('0')
      setProjectLeft('100%')
      setActiveComponent('index')
    } else if (pathname === 'about') {
      setX('100%')
      setY('0')
      setProjectLeft('0')
      setActiveComponent('about')
    } else if (pathname === 'contact') {
      setX('-200%')
      setY('0')
      setProjectLeft('0')
      setActiveComponent('contact')
    } else if (pathname === 'privacy') {
      setX('200%')
      setY('0')
      setProjectLeft('0')
      setActiveComponent('privacy')
    } else if (pathname.match('project')) {
      setX(countRequests == 0 ? '-100%' : x == '-100%' ? '-100%' : '0')
      setY('-100%')
      setProjectLeft(countRequests == 0 ? '100%' : projectLeft)
      setActiveComponent('project')
    } else {
      setX('0')
      setY('100%')
      setProjectLeft('0')
      setActiveComponent('notfound')
    }

    setCountRequests((countRequests) => countRequests + 1)
  }, [location])

  useEffect(() => {
    window.setTimeout(() => setLoaded(true), 50)
  }, [])

  const classes = classnames('App', { 'App--loaded': loaded })

  return (
    <main
      className={classes}
      style={{ transform: `translate3d(${x}, ${y}, 0)` }}
    >
      <section className="App__home">
        <Home active={activeComponent === 'home'} />
      </section>

      <section className="App__index">
        <Index active={activeComponent === 'index'} />
      </section>

      <section className="App__about">
        <Page type="about" active={activeComponent === 'about'} />
      </section>

      <section className="App__contact">
        <Page type="contact" active={activeComponent === 'contact'} />
      </section>

      <section className="App__privacy">
        <Page type="privacy" active={activeComponent === 'privacy'} />
      </section>

      <section className="App__project" style={{ left: projectLeft }}>
        <Project
          active={activeComponent === 'project'}
          firstRequest={countRequests < 2}
        />
      </section>

      <section className="App__notfound">
        <NotFound active={activeComponent === 'notfound'} />
      </section>

      <Analytics />
    </main>
  )
}

export default App
