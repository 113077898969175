import { AnyAction } from 'redux'

import { PagesStateType } from 'data/types'
import { PAGES_DATA_LOAD } from './types'

const initialState: PagesStateType = {
  about: null,
  contact: null,
  index: null,
  privacy: null,
  awards: [],
  lectures: [],
  locations: [],
  press: [],
  team: [],
}

export default function (
  state = initialState,
  action: AnyAction
): PagesStateType {
  switch (action.type) {
    case PAGES_DATA_LOAD: {
      const {
        about,
        contact,
        index,
        privacy,
        awards,
        lectures,
        locations,
        press,
        team,
      } = action.payload

      if (about || contact) {
        return {
          ...state,
          about,
          contact,
          index,
          privacy,
          awards,
          lectures,
          locations,
          press,
          team,
        }
      }

      return state
    }

    default:
      return state
  }
}
