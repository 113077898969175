import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import truncate from 'truncate'

import { ProjectType } from 'data/types'
import Image from 'view/components/image/Image'
import ProjectMeta from 'view/components/project-meta/ProjectMeta'

type IndexItemProps = {
  item: ProjectType
}

const IndexItem = ({ item }: IndexItemProps): ReactElement => {
  const { slug, title, description, thumb, meta } = item
  const descriptionShort = truncate(description, 900, { ellipsis: '…' })
  const thumbFirst = thumb.length > 0 && thumb[0]

  const classesTitle = classnames('Index__item__title', {
    'Index__item__title--has-thumb': thumbFirst,
  })

  return (
    <div className="Index__item">
      <Link to={`/project/${slug}`} className="Index__item__link">
        <div className="Index__item__grid">
          <div className="Index__item__meta">
            <ProjectMeta type="index" items={meta} awards={[]} press={[]} />
          </div>

          <div className="Index__item__image">
            <div className="Index__item__image__wrapper">
              <div className="Index__item__image__ratio" />
              {thumbFirst && (
                <Image image={thumbFirst} className="Index__item__thumb" />
              )}
              <div className={classesTitle}>{title}</div>
            </div>
          </div>

          <div
            className="Index__item__description"
            dangerouslySetInnerHTML={{ __html: descriptionShort }}
          />
        </div>
      </Link>
    </div>
  )
}

export default IndexItem
