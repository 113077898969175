import React, { ReactElement } from 'react'

import { TeamType } from 'data/types'

import './Team.scss'

type TeamProps = {
  items: TeamType[]
}

const Team = ({ items }: TeamProps): ReactElement => {
  const contents = items.map((item) => {
    const { id, title, description } = item

    return (
      <div key={id} className="Team__item">
        <div className="Team__item__title">{title}</div>
        <div
          className="Team__item__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    )
  })

  return <div className="Team">{contents}</div>
}

export default Team
