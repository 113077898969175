import React, { ReactElement, useEffect } from 'react'
import { Element, Link } from 'react-scroll'

import { PageContentsNavigationBlockType } from 'data/types'
import { addBodyClass, removeBodyClass } from 'utils/bodyClass'

import './Navigation.scss'

type NavigationProps = {
  items: PageContentsNavigationBlockType[]
  type: string
  wrapperRef: any
}

export const Navigation = ({
  items,
  type,
  wrapperRef,
}: NavigationProps): ReactElement => {
  useEffect(() => {
    if (items && items.length > 0) {
      addBodyClass('has-page-navigation')
    }

    return () => removeBodyClass('has-page-navigation')
  }, [items])

  const handleActive = (to) => {
    if (wrapperRef && wrapperRef.current) {
      const node = wrapperRef.current
      const targetAll = node.querySelectorAll('.Navigation__target')
      const target = node.querySelector(`#${to}`)

      targetAll.forEach((item) => item.classList.remove('navigation-active'))

      if (target) {
        target.classList.add('navigation-active')
      }
    }
  }

  const itemsHtml = items.map((item, index) => {
    const { id, title } = item

    return (
      <li key={id} className="Navigation__item">
        <Link
          to={`navigation-${id}`}
          containerId={`page-${type}`}
          activeClass="active"
          smooth="easeInOutQuad"
          duration={600}
          ignoreCancelEvents={true}
          spy={true}
          offset={index === 0 ? -200 : -60}
          isDynamic={true}
          onSetActive={handleActive}
        >
          <span>{title}</span>
          <span className="Navigation__item__sep">,&nbsp;</span>
        </Link>
      </li>
    )
  })

  if (itemsHtml.length > 0) {
    return (
      <nav className="Navigation">
        <ul className="Navigation__list">{itemsHtml}</ul>
      </nav>
    )
  }

  return null
}

type NavigationSpacerProps = {
  items: PageContentsNavigationBlockType[]
}

export const NavigationSpacer = ({
  items,
}: NavigationSpacerProps): ReactElement => {
  const itemsHtml = items.map((item) => {
    const { id, title } = item

    return (
      <li key={id} className="NavigationSpacer__item">
        <span>{title}</span>
        <span className="NavigationSpacer__item__sep">,&nbsp;</span>
      </li>
    )
  })

  if (itemsHtml.length > 0) {
    return (
      <nav className="NavigationSpacer">
        <ul className="NavigationSpacer__list">{itemsHtml}</ul>
      </nav>
    )
  }

  return null
}

export const NavigationElementWrapper = ({ id, children }): ReactElement => {
  return (
    <Element
      name={`navigation-${id}`}
      id={`navigation-${id}`}
      className="Navigation__target"
    >
      {children}
    </Element>
  )
}
