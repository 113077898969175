import React, { ReactElement } from 'react'

import { PageContentsSplitVideoBlockType } from 'data/types'
import Video from 'view/components/video/Video'

import './SplitVideo.scss'

const SplitVideo = ({
  videos,
  posters,
  width,
  height,
}: PageContentsSplitVideoBlockType): ReactElement => {
  const videoFirst = videos && videos.length > 0 && videos[0]
  const videoSecond = videos && videos.length > 1 && videos[1]
  const posterFirst = posters && posters.length > 0 && posters[0]
  const posterSecond = posters && posters.length > 1 && posters[1]

  return (
    <div className="SplitVideo">
      <div className="SplitVideo__left">
        {videoFirst && (
          <Video
            video={videoFirst}
            poster={posterFirst}
            width={width}
            height={height}
          />
        )}
      </div>
      <div className="SplitVideo__right">
        {videoSecond && (
          <Video
            video={videoSecond}
            poster={posterSecond}
            width={width}
            height={height}
          />
        )}
      </div>
    </div>
  )
}

export default SplitVideo
