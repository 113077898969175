export const BUILD_NAME: string | undefined = process.env.RAZZLE_BUILD_NAME
export const BUILD_VERSION: string | undefined =
  process.env.RAZZLE_BUILD_VERSION

export const IS_DEV: boolean =
  process && process.env && process.env.NODE_ENV !== 'production'
export const IS_SERVER_SIDE: boolean = typeof window === 'undefined'

export const BASE_URL: string | undefined = process.env.RAZZLE_BASE_URL
export const BACKEND_URL: string | undefined = process.env.RAZZLE_BACKEND_URL
export const GRAPHQL_ENDPOINT: string | undefined =
  process.env.RAZZLE_GRAPHQL_ENDPOINT

export const LAZYLOAD_CONFIG: any = {
  elements_selector: '.lazy',
  threshold: 300,
  class_applied: 'lazy-applied',
  class_loading: 'lazy-loading',
  class_loaded: 'lazy-loaded',
  class_error: 'lazy-error',
  class_entered: 'lazy-entered',
  class_exited: 'lazy-exited',
}
