export const addBodyClass = (className) =>
  document.body.classList.add(className)
export const removeBodyClass = (className) =>
  document.body.classList.remove(className)
export const toggleBodyClass = (className, force) =>
  document.body.classList.toggle(className, force)
export const hasBodyClass = (className) =>
  document.body.classList.contains(className)
export const hasHtmlClass = (className) =>
  document.documentElement.classList.contains(className)
