import React, { ReactElement } from 'react'

import {
  AwardContentItemType,
  PressContentItemType,
  ProjectsMetaItemType,
} from 'data/types'

import './ProjectMeta.scss'

type ProjectMetaProps = {
  type: string
  items: ProjectsMetaItemType[]
  awards: AwardContentItemType[]
  press: PressContentItemType[]
}

const ProjectMeta = ({
  type,
  items,
  awards,
  press,
}: ProjectMetaProps): ReactElement => {
  // 1. section of variable meta items
  const sectionMeta = items.map((item) => {
    const { id, hidden, hiddenIndex, title, content } = item

    if (hidden || (type === 'index' && hiddenIndex)) {
      return null
    }

    return (
      <div key={id} className="ProjectMeta__item">
        <div className="ProjectMeta__item__title">{title}</div>
        <div
          className="ProjectMeta__item__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    )
  })

  // 2. section of award items
  let awardsCount = 0
  const awardsItems = awards.map((sub) => {
    if (sub.hidden) {
      return false
    }

    awardsCount++

    let downloadOrExternalUrl = null
    if (sub.download && sub.download.length > 0) {
      downloadOrExternalUrl = sub.download[0].url
    } else if (sub.url) {
      downloadOrExternalUrl = sub.url
    }

    let content = <span>{sub.title}</span>

    if (downloadOrExternalUrl) {
      if (sub.openInSameWindow) {
        content = <a href={downloadOrExternalUrl}>{sub.title}</a>
      } else {
        content = (
          <a
            href={downloadOrExternalUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sub.title}
          </a>
        )
      }
    }

    return (
      <div key={sub.id} className="ProjectMeta__awards__item">
        {content}
      </div>
    )
  })

  const sectionAwards =
    awardsCount > 0 ? (
      <div key="awards" className="ProjectMeta__item">
        <div className="ProjectMeta__item__title">Awards</div>
        <div className="ProjectMeta__item__content">{awardsItems}</div>
      </div>
    ) : null

  // 3. section of press items
  let pressCount = 0
  const pressItems = press.map((sub) => {
    if (sub.hidden) {
      return false
    }

    pressCount++

    let downloadOrExternalUrl = null
    if (sub.download && sub.download.length > 0) {
      downloadOrExternalUrl = sub.download[0].url
    } else if (sub.url) {
      downloadOrExternalUrl = sub.url
    }

    const contentInner = (
      <div className="ProjectMeta__press__item__content">
        <span>{sub.title}</span>
        <span>{sub.publisher}</span>
        <span>{sub.date}</span>
      </div>
    )
    let content = contentInner

    if (downloadOrExternalUrl) {
      if (sub.openInSameWindow) {
        content = <a href={downloadOrExternalUrl}>{contentInner}</a>
      } else {
        content = (
          <a
            href={downloadOrExternalUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contentInner}
          </a>
        )
      }
    }

    return (
      <div key={sub.id} className="ProjectMeta__press__item">
        {content}
      </div>
    )
  })

  const sectionPress =
    pressCount > 0 ? (
      <div key="press" className="ProjectMeta__item">
        <div className="ProjectMeta__item__title">Articles</div>
        <div className="ProjectMeta__item__content">{pressItems}</div>
      </div>
    ) : null

  return (
    <div className="ProjectMeta">
      {sectionMeta}
      {sectionAwards}
      {sectionPress}
    </div>
  )
}

export default ProjectMeta
