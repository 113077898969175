import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { connect, ConnectedProps } from 'react-redux'

import { RootState } from 'data/redux/rootReducer'
import Project from './Project'

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.items,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProjectProviderProps extends PropsFromRedux {
  active: boolean
  firstRequest: boolean
}

const ProjectProvider = ({
  active,
  firstRequest,
  projects,
}: ProjectProviderProps): ReactElement => {
  const location = useLocation()
  const [activeProject, setActiveProject] = useState(
    projects.length > 0 ? projects[0] : null
  )

  useEffect(() => {
    let { pathname } = location
    const match = pathname.match(/project\/(.*)/)

    if (match && match.length > 1) {
      const slug = match[1]
      const project = projects.find((project) => project.slug === slug)
      setActiveProject(project)
    }
  }, [location, projects])

  if (activeProject) {
    return (
      <Project
        key={activeProject.id}
        item={activeProject}
        active={active}
        firstRequest={firstRequest}
      />
    )
  }

  return null
}

export default connector(ProjectProvider)
