import React, { ReactElement } from 'react'

import { LectureType } from 'data/types'

import './Lectures.scss'

type LecturesProps = {
  items: LectureType[]
}

const Lectures = ({ items }: LecturesProps): ReactElement => {
  const sections = items.map((item) => {
    const { id, title, contents } = item

    const sectionContents = contents.map((sub) => {
      if (sub.hidden) {
        return false
      }

      let downloadOrExternalUrl = null
      if (sub.download && sub.download.length > 0) {
        downloadOrExternalUrl = sub.download[0].url
      } else if (sub.url) {
        downloadOrExternalUrl = sub.url
      }

      let content = <span>{sub.title}</span>

      if (downloadOrExternalUrl) {
        if (sub.openInSameWindow) {
          content = <a href={downloadOrExternalUrl}>{sub.title}</a>
        } else {
          content = (
            <a
              href={downloadOrExternalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {sub.title}
            </a>
          )
        }
      }

      return (
        <div key={sub.id} className="Lectures__section__item">
          {content}
        </div>
      )
    })

    return (
      <div key={id} className="Lectures__section">
        <div className="Lectures__section__title">{title}</div>
        <div className="Lectures__section__content">{sectionContents}</div>
      </div>
    )
  })

  return <div className="Lectures">{sections}</div>
}

export default Lectures
