import React, { ReactElement, useRef } from 'react'
import { Link } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import classnames from 'classnames'

import { HomeProjectRelatedType, ImageType, VideoType } from 'data/types'
import Video from 'view/components/video/Video'

type HomeSectionVideoProps = {
  asset: VideoType
  poster: ImageType
  color: string
  related: HomeProjectRelatedType
  index: number
  wrapperRef: any
}

const HomeSectionVideo = ({
  asset,
  poster,
  color,
  related,
  index,
  wrapperRef,
}: HomeSectionVideoProps): ReactElement => {
  const anchorRef = useRef<HTMLDivElement>(null)

  const videoHtml = (
    <Video
      video={asset}
      poster={poster}
      width={100}
      height={44}
      className="Home__section__video"
    />
  )

  if (related) {
    const classes = classnames(
      'Home__section__title',
      color ? `h-color-${color}` : ''
    )

    const handleStickyClick = (ev) => {
      if (anchorRef && anchorRef.current && wrapperRef && wrapperRef.current) {
        ev.preventDefault()
        const scrollTop = wrapperRef.current.scrollTop
        const refY = anchorRef.current.getBoundingClientRect().y

        animateScroll.scrollTo(scrollTop + refY, {
          containerId: 'Home',
          duration: 600,
          smooth: true,
        })
      }
    }

    const stickyHtml = (
      <button className="Home__section__sticky" onClick={handleStickyClick}>
        {related.title}
      </button>
    )

    const anchorHtml = (
      <div className="Home__section__anchor" ref={anchorRef}></div>
    )

    const titleHtml = <div className={classes}>{related.title}</div>

    return (
      <div className="Home__section" style={{ '--index': index }}>
        {stickyHtml}
        {anchorHtml}
        <Link to={`/project/${related.slug}`} className="Home__section__link">
          {videoHtml}
          {titleHtml}
        </Link>
      </div>
    )
  }

  return videoHtml
}

export default HomeSectionVideo
