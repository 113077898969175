import React, { ReactElement } from 'react'

import { PageContentsTextInColumnsBlockType } from 'data/types'

import './TextInColumns.scss'

const TextInColumns = ({
  title,
  content,
}: PageContentsTextInColumnsBlockType): ReactElement => {
  return (
    <div className="TextInColumns">
      <div className="TextInColumns__inner">
        <div className="TextInColumns__title">{title}</div>
        <div
          className="TextInColumns__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default TextInColumns
