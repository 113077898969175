import 'intersection-observer'
import React, { CSSProperties, ReactElement, useEffect, useRef } from 'react'
import classnames from 'classnames'
import LazyLoad from 'vanilla-lazyload'
import { useInView } from 'react-intersection-observer'

import { IS_SERVER_SIDE, LAZYLOAD_CONFIG } from 'Constants'
import { ImageType, VideoType } from 'data/types'

import './Video.scss'

type VideoProps = {
  video: VideoType
  width: number
  height: number
  poster?: ImageType
  muted?: boolean
  loop?: boolean
  playsInline?: boolean
  autoPlay?: boolean
  preload?: string
  className?: string
  style?: CSSProperties
}

// Only initialize it one time for the entire application
if (!IS_SERVER_SIDE && !(window.document as any).lazyLoadInstance) {
  ;(window.document as any).lazyLoadInstance = new LazyLoad(LAZYLOAD_CONFIG)
}

const Video = ({
  video,
  width,
  height,
  poster,
  muted,
  loop,
  playsInline,
  autoPlay,
  preload,
  className,
  style,
}: VideoProps): ReactElement => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [ref, inView] = useInView({
    threshold: 0.75,
  })

  useEffect(() => {
    ;(window.document as any).lazyLoadInstance.update()
  }, [])

  useEffect(() => {
    let isCancelled = false

    if (!isCancelled) {
      try {
        if (videoRef && videoRef.current) {
          // only once video has been loaded
          if (videoRef.current.classList.contains('lazy-loaded')) {
            if (inView) {
              videoRef.current.play()
            } else {
              videoRef.current.pause()
            }
          }
        }
      } catch (e) {
        if (!isCancelled) {
          throw e
        }
      }
    }

    return () => {
      isCancelled = true
    }
  }, [videoRef, inView])

  const classes = classnames(
    'Video',
    { 'Video--has-poster': poster },
    className
  )
  const ratio = height / (width > 0 ? width : 1)
  const smallestImgUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

  return (
    <div ref={ref} className={classes} style={{ ...style }}>
      <div className="Video__ratio" style={{ paddingTop: `${100 * ratio}%` }} />
      {poster && (
        <img
          className="Video__poster lazy"
          alt={poster.title}
          src={smallestImgUrl}
          data-src={poster.url}
        />
      )}
      <video
        ref={videoRef}
        className="Video__video lazy"
        muted={muted ?? true}
        loop={loop ?? true}
        playsInline={playsInline ?? true}
        autoPlay={autoPlay ?? true}
        preload={preload ?? 'none'}
      >
        <source data-src={video.url} />
      </video>
    </div>
  )
}

export default Video
