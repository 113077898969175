import React, { ReactElement } from 'react'

import './SlideshowCursor.scss'

type SlideshowCursorProps = {
  coords?: [number, number]
  direction: string
}

const SlideshowCursor = ({
  coords,
  direction,
}: SlideshowCursorProps): ReactElement | null => {
  if (!coords) {
    return null
  }

  const style = {
    transform: `translate(${coords[0] - 10}px, ${coords[1] - 10}px)`,
  }

  const textHtml = direction === 'left' ? 'Prev' : 'Next'

  return (
    <div className="SlideshowCursor" style={style}>
      <div className="SlideshowCursor__inner">{textHtml}</div>
    </div>
  )
}

export default SlideshowCursor
