import React, { ReactElement, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { uiHideCookieBanner } from 'data/redux/ui/actions'
import { addBodyClass, removeBodyClass } from 'utils/bodyClass'
import { motionCookieBannerVariants } from 'utils/motionVariants'

import './CookieBanner.scss'

const mapStateToProps = (state: RootState) => ({
  showCookie: state.ui.showCookie,
})

const mapDispatchToProps = {
  actionUIHideCookieBanner: uiHideCookieBanner,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const CookieBanner = ({
  showCookie,
  actionUIHideCookieBanner,
}: PropsFromRedux): ReactElement => {
  const [acceptedCookie, setAcceptedCookie] = useState(false)

  useEffect(() => {
    const acceptedItem =
      localStorage && localStorage.getItem('morkulnes_privacy_accepted')
    const isAccepted = acceptedItem === 'yeah'

    if (!isAccepted) {
      addBodyClass('has-cookie-banner')
    }

    setAcceptedCookie(isAccepted)
  }, [setAcceptedCookie])

  const acceptCookies = () => {
    localStorage.setItem('morkulnes_privacy_accepted', 'yeah')
    actionUIHideCookieBanner()
  }

  const animationComplete = (definition) => {
    if (definition === 'hidden') {
      removeBodyClass('has-cookie-banner')
    }
  }

  if (!acceptedCookie) {
    return (
      <motion.div
        className="CookieBanner"
        variants={motionCookieBannerVariants}
        initial="visible"
        animate={showCookie ? 'visible' : 'hidden'}
        transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 0.6 }}
        onAnimationComplete={animationComplete}
      >
        <div className="CookieBanner__inner">
          <Link className="CookieBanner__read" to="/privacy">
            This site uses cookies
          </Link>{' '}
          <button onClick={acceptCookies} className="CookieBanner__button">
            Accept
          </button>
        </div>
      </motion.div>
    )
  }

  return null
}

export default connector(CookieBanner)
