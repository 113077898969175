import { AnyAction } from 'redux'

import { UIStateType } from 'data/types'
import { UI_HIDE_COOKIE_BANNER } from './types'

const initialState: UIStateType = {
  showCookie: true,
}

export default function (state = initialState, action: AnyAction): UIStateType {
  switch (action.type) {
    case UI_HIDE_COOKIE_BANNER: {
      return {
        ...state,
        showCookie: false,
      }
    }

    default:
      return state
  }
}
