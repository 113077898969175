export const motionProjectIndexVariants = {
  hidden: {
    y: '-100%',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  visible: {
    y: '0',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
}

export const motionCookieBannerVariants = {
  hidden: {
    y: '100%',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  visible: {
    y: '0',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
}

export const motionHeaderNavVariants = {
  hidden: {
    height: 0,
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  visible: {
    height: 'auto',
    transition: {
      type: 'tween',
      bounce: 0,
      delay: 0.1,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
}

export const motionHeaderClaimVariants = {
  hidden: {
    height: 6,
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  visible: {
    height: 'auto',
    transition: {
      type: 'tween',
      bounce: 0,
      delay: 0.1,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
}
