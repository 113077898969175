import React, { ReactElement, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import {
  PageContentsImageBlockType,
  PageContentsSplitVideoBlockType,
  PageContentsTextBlockType,
  PageContentsTextInColumnsBlockType,
  PageContentsVideoBlockType,
} from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Header from 'view/components/header/Header'
import HeaderSpacer from 'view/components/header/HeaderSpacer'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Video from 'view/components/video/Video'
import Awards from './contents/Awards'
import Figure from './contents/Figure'
import Lectures from './contents/Lectures'
import Locations from './contents/Locations'
import {
  Navigation,
  NavigationElementWrapper,
  NavigationSpacer,
} from './contents/Navigation'
import Press from './contents/Press'
import Team from './contents/Team'
import Text from './contents/Text'
import TextInColumns from './contents/TextInColumns'
import Spacer from './contents/Spacer'
import SplitVideo from './contents/SplitVideo'

import './Page.scss'

type contentSectionType = {
  id: string
  items: any[]
}

const mapStateToProps = (state: RootState) => ({
  pages: state.pages,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface PageProps extends PropsFromRedux {
  active: boolean
  type: string
}

const Page = ({ pages, active, type }: PageProps): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const { about, contact, privacy, awards, lectures, locations, press, team } =
    pages
  const page = type === 'about' ? about : type === 'privacy' ? privacy : contact

  const navigationItems = []
  let navigationId = '1'
  let navigationSpacerId = 10000
  const contentSections: contentSectionType[] = []

  page.contents.map((item) => {
    const { id, hidden, type } = item

    if (hidden) {
      return null
    }

    let currentContentSection = contentSections.find(
      (item) => item.id === navigationId
    )

    if (!currentContentSection) {
      currentContentSection = {
        id: navigationId,
        items: [],
      }

      contentSections.push(currentContentSection)
    }

    if (type === 'pageContents_awards_BlockType') {
      currentContentSection.items.push(<Awards key={id} items={awards} />)
    } else if (type === 'pageContents_image_BlockType') {
      const itemTyped = item as PageContentsImageBlockType
      currentContentSection.items.push(<Figure key={id} {...itemTyped} />)
    } else if (type === 'pageContents_lectures_BlockType') {
      currentContentSection.items.push(<Lectures key={id} items={lectures} />)
    } else if (type === 'pageContents_location_BlockType') {
      currentContentSection.items.push(<Locations key={id} items={locations} />)
    } else if (type === 'pageContents_navigation_BlockType') {
      navigationItems.push(item)
      navigationId = id
    } else if (type === 'pageContents_press_BlockType') {
      currentContentSection.items.push(<Press key={id} items={press} />)
    } else if (type === 'pageContents_team_BlockType') {
      currentContentSection.items.push(<Team key={id} items={team} />)
    } else if (type === 'pageContents_text_BlockType') {
      const itemTyped = item as PageContentsTextBlockType
      currentContentSection.items.push(<Text key={id} {...itemTyped} />)
    } else if (type === 'pageContents_textInColumns_BlockType') {
      const itemTyped = item as PageContentsTextInColumnsBlockType
      currentContentSection.items.push(
        <TextInColumns key={id} {...itemTyped} />
      )
    } else if (type === 'pageContents_video_BlockType') {
      const itemTyped = item as PageContentsVideoBlockType
      const videoFirst =
        itemTyped.video && itemTyped.video.length > 0 && itemTyped.video[0]

      if (videoFirst) {
        const posterFirst =
          itemTyped.poster && itemTyped.poster.length > 0 && itemTyped.poster[0]

        const itemTypedVideo = {
          ...itemTyped,
          video: videoFirst,
          poster: posterFirst,
        }

        currentContentSection.items.push(<Video key={id} {...itemTypedVideo} />)
      }
    } else if (type === 'pageContents_spacer_BlockType') {
      // create a new content section
      navigationId = (++navigationSpacerId).toString()
      currentContentSection = {
        id: navigationId,
        items: [],
      }

      contentSections.push(currentContentSection)

      currentContentSection.items.push(<Spacer key={id} />)
    } else if (type === 'pageContents_splitVideos_BlockType') {
      const itemTyped = item as PageContentsSplitVideoBlockType
      currentContentSection.items.push(<SplitVideo key={id} {...itemTyped} />)
    }
  })

  const contents = contentSections.map((section) => {
    const { id, items } = section

    if (items.length === 0) {
      return null
    }

    return (
      <NavigationElementWrapper key={id} id={id}>
        {items}
      </NavigationElementWrapper>
    )
  })

  const helmetParams = {
    title: page.metaTitle,
    description: page.metaDescription,
    url: `${BASE_URL}/${type}`,
  }

  return (
    <>
      {active && <Helmet {...helmetParams} />}

      <Header type={type} wrapperRef={wrapperRef} active={active} />

      {active && (
        <Navigation
          items={navigationItems}
          type={type}
          wrapperRef={wrapperRef}
        />
      )}

      <div className="Page" id={`page-${type}`} ref={wrapperRef}>
        <HeaderSpacer type={type} />
        <NavigationSpacer items={navigationItems} />
        <div className="Page__inner">{contents}</div>
      </div>

      <CookieBanner />
    </>
  )
}

export default connector(Page)
