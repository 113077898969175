import React, { ReactElement } from 'react'

import { LocationType } from 'data/types'
import Clock from 'view/components/clock/Clock'

import './Locations.scss'

type LocationsProps = {
  items: LocationType[]
}

const Locations = ({ items }: LocationsProps): ReactElement => {
  const sections = items.map((item) => {
    const { id, description, timezone, mapsUrl } = item

    const clockHtml = timezone && (
      <div className="Locations__item__clock">
        <Clock timezone={timezone} />
      </div>
    )

    const descriptionHtml = (
      <div
        className="Locations__item__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )

    const mapsHtml = mapsUrl && (
      <div className="Locations__item__maps">
        <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
          (View on map)
        </a>
      </div>
    )

    return (
      <div key={id} className="Locations__item">
        {clockHtml}
        {descriptionHtml}
        {mapsHtml}
      </div>
    )
  })

  return <div className="Locations">{sections}</div>
}

export default Locations
