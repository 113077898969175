import { AnyAction } from 'redux'

import { BASE_URL } from 'Constants'
import { MetaStateType } from 'data/types'
import { META_DATA_LOAD } from './types'

const initialState: MetaStateType = {
  id: '',
  title: '',
  description: '',
  image: [],
  header: '',
  url: BASE_URL,
  googleAnalyticsId: '',
}

export default function (
  state = initialState,
  action: AnyAction
): MetaStateType {
  switch (action.type) {
    case META_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        const { id, title, description, image, header, googleAnalyticsId } =
          data

        return {
          ...state,
          id,
          title,
          description,
          image,
          header,
          googleAnalyticsId,
        }
      }

      return state
    }

    default:
      return state
  }
}
