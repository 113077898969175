import React, { CSSProperties, ReactElement, useEffect } from 'react'
import classnames from 'classnames'
import LazyLoad from 'vanilla-lazyload'

import { IS_SERVER_SIDE, LAZYLOAD_CONFIG } from 'Constants'
import { ImageType } from 'data/types'

import './Image.scss'

type ImageProps = {
  image: ImageType
  className?: string
  style?: CSSProperties
}

// Only initialize it one time for the entire application
if (!IS_SERVER_SIDE && !(window.document as any).lazyLoadInstance) {
  ;(window.document as any).lazyLoadInstance = new LazyLoad(LAZYLOAD_CONFIG)
}

const Image = ({ image, className, style }: ImageProps): ReactElement => {
  useEffect(() => {
    ;(window.document as any).lazyLoadInstance.update()
  }, [])

  const classes = classnames('Image', 'lazy', className)
  const smallestImgUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

  return (
    <img
      className={classes}
      style={{ ...style }}
      key={image.url}
      alt={image.title}
      width={image.width}
      height={image.height}
      src={smallestImgUrl}
      data-src={image.url}
    />
  )
}

export default Image
