import React, { ReactElement } from 'react'

import { PageContentsImageBlockType } from 'data/types'
import Image from 'view/components/image/Image'

const Figure = ({ image }: PageContentsImageBlockType): ReactElement => {
  const firstImage = image.length > 0 && image[0]

  if (firstImage) {
    return (
      <figure className="Figure">
        <div
          className="Figure__ratio"
          style={{
            paddingTop: `${(firstImage.height / firstImage.width) * 100}%`,
          }}
        ></div>
        <Image image={firstImage} className="Figure__img" />
      </figure>
    )
  }

  return null
}

export default Figure
