import React, { ReactElement } from 'react'

import { PressType } from 'data/types'

import './Press.scss'

type PressProps = {
  items: PressType[]
}

const Press = ({ items }: PressProps): ReactElement => {
  const sections = items.map((item) => {
    const { id, title, contents } = item

    const sectionContents = contents.map((sub) => {
      if (sub.hidden) {
        return false
      }

      let downloadOrExternalUrl = null
      if (sub.download && sub.download.length > 0) {
        downloadOrExternalUrl = sub.download[0].url
      } else if (sub.url) {
        downloadOrExternalUrl = sub.url
      }

      const contentInner = (
        <div className="Press__section__item__table">
          <div className="Press__section__item__1">{sub.title}</div>
          <div className="Press__section__item__2">{sub.publisher}</div>
          <div className="Press__section__item__3">{sub.date}</div>
        </div>
      )
      let content = contentInner

      if (downloadOrExternalUrl) {
        if (sub.openInSameWindow) {
          content = <a href={downloadOrExternalUrl}>{contentInner}</a>
        } else {
          content = (
            <a
              href={downloadOrExternalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contentInner}
            </a>
          )
        }
      }

      return (
        <div key={sub.id} className="Press__section__item">
          {content}
        </div>
      )
    })

    return (
      <div key={id} className="Press__section">
        <div className="Press__section__title">{title}</div>
        <div className="Press__section__content">{sectionContents}</div>
      </div>
    )
  })

  return <div className="Press">{sections}</div>
}

export default Press
