import React, { ReactElement } from 'react'

import { PageContentsTextBlockType } from 'data/types'

import './Text.scss'

const Text = ({ content }: PageContentsTextBlockType): ReactElement => (
  <div className="Text" dangerouslySetInnerHTML={{ __html: content }} />
)

export default Text
