import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import striptags from 'striptags'

import { RootState } from 'data/redux/rootReducer'

import './HeaderSpacer.scss'

const mapStateToProps = (state: RootState) => ({
  claim: state.meta.header,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface HeaderSpacerProps extends PropsFromRedux {
  type: string
}

const HeaderSpacer = ({ claim, type }: HeaderSpacerProps): ReactElement => {
  const claimClean = striptags(claim, ['br', 'strong', 'a'])

  return (
    <div className={`HeaderSpacer HeaderSpacer--claim HeaderSpacer--${type}`}>
      <div className="HeaderSpacer__claim">
        <div className="HeaderSpacer__claim__inner">
          MORK-ULNES{' '}
          <span
            className="HeaderSpacer__claim__text"
            dangerouslySetInnerHTML={{ __html: claimClean }}
          />
        </div>
      </div>

      {type !== 'notfound' && (
        <div className="HeaderSpacer__nav">
          <div className="HeaderSpacer__nav__inner">
            <ul className="HeaderSpacer__nav__list">
              <li className="HeaderSpacer__nav__item">
                <span className="HeaderSpacer__nav__link">About</span>
              </li>
              <li className="HeaderSpacer__nav__item">
                <span className="HeaderSpacer__nav__link">
                  Selected Projects,
                </span>{' '}
                <span className="HeaderSpacer__nav__link">Projects</span>
              </li>
              <li className="HeaderSpacer__nav__item">
                <span className="HeaderSpacer__nav__link">Contact</span>
              </li>
            </ul>

            <div className="HeaderSpacer__nav__small">
              <span className="HeaderSpacer__nav__link">About,</span>{' '}
              <span className="HeaderSpacer__nav__link">Contact</span>
              <br />
              <span className="HeaderSpacer__nav__link">
                Selected Projects,
              </span>{' '}
              <span className="HeaderSpacer__nav__link">Projects</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default connector(HeaderSpacer)
