import React, { MouseEvent, ReactElement, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useLocation, useNavigate } from 'react-router'
import { motion } from 'framer-motion'
import { Keyboard, Navigation } from 'swiper'

import { BASE_URL } from 'Constants'
import { ProjectType } from 'data/types'
import { motionProjectIndexVariants } from 'utils/motionVariants'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Image from 'view/components/image/Image'
import Video from 'view/components/video/Video'
import ProjectMeta from 'view/components/project-meta/ProjectMeta'
import SlideshowCursor from './SlideshowCursor'

import 'swiper/css'
import './Project.scss'

type ProjectProps = {
  active: boolean
  firstRequest: boolean
  item: ProjectType
}

const Project = ({
  active,
  firstRequest,
  item,
}: ProjectProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0])
  const [cursorDirection, setCursorDirection] = useState('left')
  const [swiper, setSwiper] = useState(null)
  const [showIndex, setShowIndex] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const {
    slug,
    title,
    description,
    thumb,
    preview,
    slideshow,
    meta,
    awards,
    press,
  } = item
  const thumbFirst = thumb.length > 0 && thumb[0]

  const actionBackButton = () => {
    if (showIndex) {
      setShowIndex(false)
    } else {
      // go back or to index if first location entry
      if (firstRequest) {
        navigate('/index')
      } else {
        navigate(-1)
      }
    }
  }

  const actionIndexButton = () => setShowIndex(!showIndex)
  const actionGoToSlide = (index: number) => () => {
    if (swiper) {
      swiper.slideTo(index, 0)
      setShowIndex(false)
    }
  }

  const onMouseOver = () => {
    if (!(window as any).Modernizr.touchevents) {
      setHover(true)
    }
  }

  const onMouseMove = (ev: MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const mouseCoords: [number, number] = [ev.clientX + 20, ev.clientY + 20]

      setMouseCoords(mouseCoords)
      setCursorDirection(ev.clientX < rect.width / 2 ? 'left' : 'right')
    }
  }

  const onMouseLeave = () => {
    if (!(window as any).Modernizr.touchevents) {
      setHover(false)
    }
  }

  const onFocus = () => void 0

  const headerHtml = (
    <div className="Project__header">
      <div className="Project__header__left">
        <button className="Project__btn" onClick={actionIndexButton}>
          {title}
          <span className="Project__header__left__small">
            {' '}
            / Project Details
          </span>
        </button>
      </div>
      <div className="Project__header__center">
        <button className="Project__btn" onClick={actionIndexButton}>
          Project Details
        </button>
      </div>
      <div className="Project__header__right">
        <button className="Project__btn" onClick={actionBackButton}>
          Close
        </button>
      </div>
    </div>
  )

  const previewItems = preview.map((item, index) => {
    let { id, kind, width, height } = item
    let html = null

    if (kind === 'image') {
      html = (
        <Image image={item} className="Project__index__preview__item__img" />
      )
    } else if (kind === 'video') {
      html = (
        <Video
          video={item}
          width={16}
          height={9}
          className="Project__index__preview__item__video"
        />
      )
      width = 16
      height = 9
    }

    const ratio = width / (height > 0 ? height : 1)

    if (html) {
      return (
        <div
          key={id}
          className="Project__index__preview__item"
          style={{ '--r': ratio }}
        >
          <button
            onClick={actionGoToSlide(index + 1)}
            className="Project__index__preview__item__btn"
          >
            {html}
          </button>
        </div>
      )
    }

    return null
  })

  const indexHtml = (
    <motion.div
      className="Project__index"
      variants={motionProjectIndexVariants}
      initial="hidden"
      animate={showIndex ? 'visible' : 'hidden'}
    >
      <div className="Project__index__preview">{previewItems}</div>
      <div className="Project__index__content">
        <div className="Project__index__content__meta">
          <ProjectMeta
            type="project"
            items={meta}
            awards={awards}
            press={press}
          />
        </div>
        <div className="Project__index__content__description">
          <div
            className="Project__index__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </motion.div>
  )

  const slideshowItems = slideshow.map((item) => {
    const { id, kind } = item
    let html = null

    if (kind === 'image') {
      html = <Image image={item} className="Project__slideshow__item" />
    } else if (kind === 'video') {
      html = (
        <Video
          video={item}
          width={100}
          height={100}
          className="Project__slideshow__item"
        />
      )
    }

    if (html) {
      return <SwiperSlide key={id}>{html}</SwiperSlide>
    }

    return null
  })

  const slideshowHtml = (
    <div
      className="Project__slideshow"
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
    >
      <Swiper
        modules={[Keyboard, Navigation]}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        speed={450}
        keyboard
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        }}
        onSwiper={setSwiper}
      >
        {slideshowItems}
      </Swiper>

      <div className="Project__slideshow__button__prev" ref={prevRef} />
      <div className="Project__slideshow__button__next" ref={nextRef} />
    </div>
  )

  const hoverHtml = hover && (
    <SlideshowCursor coords={mouseCoords} direction={cursorDirection} />
  )

  const helmetParams = {
    title: title,
    description: description,
    url: `${BASE_URL}/project/${slug}`,
    image: thumbFirst ? [thumbFirst] : [],
  }

  return (
    <>
      {active && <Helmet {...helmetParams} />}

      <div className="Project">
        {headerHtml}
        {indexHtml}
        {slideshowHtml}
        {hoverHtml}
      </div>
    </>
  )
}

export default Project
